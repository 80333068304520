// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Fields
import { Checkbox, ResponsiveModal } from "fields";
import TimePicker from "react-time-picker";

// Lib
import { Constants } from "lib";

// Icons
import { ArrowIcon, MinusIcon, PlusIcon } from "assets/Icons";

class RegularHoursEditModal extends React.Component {
  onAddExtraHours = ({ day }) => {
    const { selected_date_options = {} } = this.props;
    const { extraHours = {} } = selected_date_options[day] || {};
    const extraHoursCount = Object.keys(extraHours).length;
    const path = `${day}.extraHours.${`range${extraHoursCount + 1}`}`;
    this.props.onChangeSelectHours(
      set(selected_date_options, path, { endAt: "", startAt: "" })
    );
  };

  onChangeExtraHoursStartTime = ({ day, hoursId, utc_time }) => {
    const path = `${day}.extraHours.${hoursId}.startAt`;
    this.props.onChangeSelectHours(
      set(this.props.selected_date_options, path, utc_time)
    );
  };

  onChangeExtraHoursEndTime = ({ day, hoursId, utc_time }) => {
    const path = `${day}.extraHours.${hoursId}.endAt`;
    this.props.onChangeSelectHours(
      set(this.props.selected_date_options, path, utc_time)
    );
  };

  onChangeStartTime = ({ day, utc_time }) =>
    this.props.onChangeSelectHours(
      set(this.props.selected_date_options, `${day}.startAt`, utc_time)
    );

  onChangeEndTime = ({ day, utc_time }) =>
    this.props.onChangeSelectHours(
      set(this.props.selected_date_options, `${day}.endAt`, utc_time)
    );

  onRemoveExtraHours = ({ day, id }) =>
    this.props.onChangeSelectHours(
      del(this.props.selected_date_options, `${day}.extraHours.${id}`)
    );

  onSelectAllDay = ({ day, is_all_day }) => {
    const { selected_date_options = {} } = this.props;
    this.props.onChangeSelectHours(
      set(selected_date_options, `${day}.is_all_day`, is_all_day ? false : true)
    );
  };

  onSelectClosedAllDay = ({ day, is_closed_all_day }) => {
    const { selected_date_options = {} } = this.props;
    this.props.onChangeSelectHours(
      set(
        selected_date_options,
        `${day}.is_closed_all_day`,
        is_closed_all_day ? false : true
      )
    );
  };

  renderContent = () => {
    const { DAYS_IN_A_WEEK } = Constants;
    const { selected_date_options = {} } = this.props;
    return Object.keys(DAYS_IN_A_WEEK).map((day) => {
      const {
        endAt = "",
        is_all_day,
        is_closed_all_day,
        extraHours = {},
        startAt = "",
      } = selected_date_options[day] || {};
      return (
        <div className={Style.day_container} key={day}>
          {this.renderWeekdayLabel({ day, is_all_day, is_closed_all_day })}
          {!is_all_day && !is_closed_all_day && (
            <div className={Style.hours}>
              <div className={Style.time_pickers_and_button}>
                {this.renderTimePickers({ day, endAt, startAt })}
                {Object.keys(extraHours).length === 0 && (
                  <button
                    className={Style.add_hours_button}
                    onClick={() => this.onAddExtraHours({ day })}
                  >
                    <PlusIcon />
                  </button>
                )}
              </div>
              {Object.keys(extraHours).length > 0 &&
                this.renderExtraHours({ day, extraHours })}
            </div>
          )}
        </div>
      );
    });
  };

  renderExtraHours = ({ day, extraHours = {} }) =>
    Object.keys(extraHours).map((id) => {
      const { endAt = "", startAt = "" } = extraHours[id];
      const params = { day, endAt, hoursId: id, startAt };
      return (
        <div className={Style.weekday} key={`${day}-${id}`}>
          <div
            className={Style.time_pickers_and_button}
            style={{ marginTop: "1rem" }}
          >
            {this.renderTimePickersForExtraHours(params)}
            <button
              className={Style.remove_hours_button}
              onClick={() => this.onRemoveExtraHours({ day, id })}
            >
              <MinusIcon />
            </button>
          </div>
        </div>
      );
    });

  renderTimePickers = ({ day, endAt = "", startAt = "" }) => (
    <div className={Style.time_pickers}>
      <div>
        <TimePicker
          className={Style.time_picker}
          value={startAt}
          disableClock
          name={`Start time picker for ${day}`}
          onChange={(utc_time) => this.onChangeStartTime({ day, utc_time })}
        />
      </div>
      <div className={Style.arrowIcon}>
        <ArrowIcon />
      </div>
      <div>
        <TimePicker
          className={Style.time_picker}
          value={endAt}
          disableClock
          name={`End time picker for ${day}`}
          onChange={(utc_time) => this.onChangeEndTime({ day, utc_time })}
        />
      </div>
    </div>
  );

  renderTimePickersForExtraHours = ({
    day,
    endAt = "",
    hoursId = "",
    startAt = "",
  }) => (
    <div className={Style.time_pickers}>
      <div>
        <TimePicker
          className={Style.time_picker}
          value={startAt}
          disableClock
          name={`Start time picker for ${day}`}
          onChange={(utc_time) =>
            this.onChangeExtraHoursStartTime({ day, hoursId, utc_time })
          }
        />
      </div>
      <div className={Style.arrowIcon}>
        <ArrowIcon />
      </div>
      <div>
        <TimePicker
          className={Style.time_picker}
          value={endAt}
          disableClock
          name={`End time picker for ${day}`}
          onChange={(utc_time) =>
            this.onChangeExtraHoursEndTime({ day, hoursId, utc_time })
          }
        />
      </div>
    </div>
  );

  renderWeekdayLabel = ({ day, is_closed_all_day }) => (
    <div className={Style.day_label_container}>
      <div className={Style.day_label}>{day}</div>
      <div>
        <Checkbox
          checked={is_closed_all_day}
          id={`Select available day on ${day}`}
          label="Not Available"
          onClick={() => this.onSelectClosedAllDay({ day, is_closed_all_day })}
        />
      </div>
    </div>
  );

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentLabel="Edit Available hours modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: { activeText: "Save", loadingText: "Saving" },
        }}
        headerProps={{ header: "Available Hours" }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

RegularHoursEditModal.propTypes = {
  onChangeSelectHours: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  selected_date_options: PropTypes.object,
};

export default RegularHoursEditModal;
