// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { Box, Dropdown, Input } from "fields";

import Style from "./style.module.scss";

class AfterGuestCheckin extends React.Component {
  renderAutomatedTextAfterCheckin = ({ automated_text_after_checkin = "" }) => (
    <Input.TextInput
      hasMultipleLines
      label="Automated text message"
      max_character_count={200}
      name="Automated text message after check-in"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "after_checkin.automated_text_after_checkin",
          value,
        })
      }
      rows={3}
      value={automated_text_after_checkin}
    />
  );

  renderAutomatedTextAfterCheckIn2Hours = ({
    automated_text_after_check_in_with_a_delay_time = "",
    delay_time = "",
  }) => (
    <div>
      <Input.TextInput
        hasMultipleLines
        max_character_count={200}
        name={`Automated text message after check-in ${delay_time} ${
          delay_time === "1" ? "hour" : "hours"
        }`}
        custom_label={this.renderSelectTimeDelay({ delay_time })}
        onChange={(value) =>
          this.props.onChangeGuestCheckin({
            key:
              "after_checkin.automated_text_after_check_in_with_a_delay_time",
            value,
          })
        }
        rows={3}
        value={automated_text_after_check_in_with_a_delay_time}
      />
    </div>
  );

  renderSelectTimeDelay = ({ delay_time = "" }) => {
    return (
      <div className={Style.select_time_container}>
        <div className={Style.label_container}>
          Automated text message after check-in
        </div>

        <Dropdown
          placeholder="Select delay time"
          modal_props={{
            headerProps: { header: "Select delay time" },
            classNames: { modal: Style.modal },
          }}
          class_names={{ placeholder_container: Style.placeholder_container }}
          selected_option_id={delay_time}
          options={Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => ({
            id: hour,
            name: `${hour} ${hour === 1 ? "hour" : "hours"}`,
          }))}
          onSelectOption={(option) =>
            this.props.onChangeGuestCheckin({
              key: "after_checkin.delay_time",
              value: option.id,
            })
          }
          renderOption={(option) => (
            <div className={Style.option_name}>{option.name}</div>
          )}
        />
      </div>
    );
  };

  renderCheckInInstruction = ({ instruction_after_checkin = "" }) => (
    <Input.TextInput
      hasMultipleLines
      label="Instruction for guest"
      max_character_count={100}
      name="Instruction for guest after check-in"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "after_checkin.instruction_after_checkin",
          value,
        })
      }
      required
      value={instruction_after_checkin}
      rows={3}
    />
  );

  render() {
    const { after_checkin = {} } = this.props;
    const {
      automated_text_after_checkin = "",
      automated_text_after_check_in_with_a_delay_time = "",
      delay_time = "",
      instruction_after_checkin = "",
    } = after_checkin;

    return (
      <Box header="After check-in">
        {this.renderCheckInInstruction({ instruction_after_checkin })}
        {this.renderAutomatedTextAfterCheckin({ automated_text_after_checkin })}
        {this.renderAutomatedTextAfterCheckIn2Hours({
          automated_text_after_check_in_with_a_delay_time,
          delay_time,
        })}
      </Box>
    );
  }
}

AfterGuestCheckin.propTypes = {
  after_checkin: PropTypes.shape({
    automated_text_after_checkin: PropTypes.string.isRequired,
    instruction_after_checkin: PropTypes.string.isRequired,
    automated_text_after_check_in_with_a_delay_time:
      PropTypes.string.isRequired,
    delay_time: PropTypes.string.isRequired,
  }).isRequired,
  onChangeGuestCheckin: PropTypes.func.isRequired,
};

AfterGuestCheckin.defaultProps = {
  after_checkin: {
    automated_text_after_checkin: "",
    instruction_after_checkin: "",
    automated_text_after_check_in_with_a_delay_time: "",
    delay_time: "",
  },
};

export default AfterGuestCheckin;
