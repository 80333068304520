// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";

// Style
import LargeCenterModalStyle from "components/Modals/style.module.scss";

// Components
import { MenusManagementModules, Modals } from "components";

// Fields
import { Box, Button, Input } from "fields";

// Utils
import { _removeAccents } from "utils";

// Lib
import { Constants } from "lib";
import Availability from "./Availability";
import { ConvertUTCToLocalTime } from "./HelperFunctions";

class GroupFormFields extends Component {
  state = { groupInfo: {} };

  componentDidMount = () => {
    this.updateSelectedDateOptions();
  };

  updateSelectedDateOptions = () => {
    const {
      groupInfo = {},
      openHours = {},
      timeZone = "America/New_York",
    } = this.props;
    const { time_setting = {} } = groupInfo;
    let { selected_date_options = {} } = time_setting;

    Object.keys(openHours).forEach((key) => {
      if (!selected_date_options.hasOwnProperty(key)) {
        let { startAt = "", endAt = "" } = openHours[key];

        selected_date_options[key] = {
          ...openHours[key],
          startAt: startAt
            ? ConvertUTCToLocalTime({ utc_time: startAt, timeZone })
            : "",
          endAt: endAt
            ? ConvertUTCToLocalTime({ utc_time: endAt, timeZone })
            : "",
        };
      }
    });

   this.setState({
      groupInfo: {
        ...groupInfo,
        time_setting: {
          ...time_setting,
          selected_date_options,
        },
      },
      openHours,
    });
  };

 

  onAddItemToGroup = (itemID) =>
    this.setState({
      groupInfo: set(this.state.groupInfo, `items.${itemID}`, "buti"),
    });

  onAddModifierGroupToGroup = (modifierGroupID) =>
    this.setState({
      groupInfo: set(
        this.state.groupInfo,
        `modifierGroups.${modifierGroupID}`,
        "buti"
      ),
    });

  onChangeGroupInfo = (fieldID, value) =>
    this.setState({
      groupInfo: set(
        this.state.groupInfo,
        fieldID,
        _removeAccents({ text: value })
      ),
    });

  onHideAddItemModal = () => this.setState({ showAddItemModal: false });

  onHideAddModifierGroupModal = () =>
    this.setState({ showAddModifierGroupModal: false });

  onRemoveItem = (itemID) =>
    this.setState({
      groupInfo: del(this.state.groupInfo, `items.${itemID}`),
    });

  onRemoveModifierGroup = (modifierGroupID) =>
    this.setState({
      groupInfo: del(this.state.groupInfo, `modifierGroups.${modifierGroupID}`),
    });

  updateTimeSetting = (fieldID, optionID, keep_only_fieldID = false) => {
    const { groupInfo = {} } = this.state;
    const updatedGroupInfo = set(
      groupInfo,
      `time_setting.${fieldID}`,
      optionID
    );
    const { time_setting = {} } = updatedGroupInfo;

    if (keep_only_fieldID) {
      Object.keys(time_setting).forEach((key) => {
        if (key !== fieldID) {
          delete time_setting[key];
        }
      });
    } else {
      delete time_setting.start_time;
      delete time_setting.end_time;
    }

    this.setState({ groupInfo: updatedGroupInfo });
  };

  handleTimeChange = (field, value) => {
    const { groupInfo = {} } = this.state;
    this.setState({
      groupInfo: set(groupInfo, `time_setting.${field}`, value),
    });
  };

  onShowAddItemModal = () => this.setState({ showAddItemModal: true });

  onShowAddModifierGroupModal = () =>
    this.setState({ showAddModifierGroupModal: true });

  renderGroupFields = () => {
    const { NEW_GROUP_FIELDS } = Constants;
    return NEW_GROUP_FIELDS.map((field) => {
      const { fieldKind, id } = field;
      return (
        fieldKind === "text" && (
          <div className={LargeCenterModalStyle.formField} key={id}>
            <Input.TextInput
              {...field}
              name={id}
              onChange={(v) => this.onChangeGroupInfo(id, v)}
              readOnly={this.props.isReadOnly}
              value={this.state.groupInfo[id] || ""}
            />
          </div>
        )
      );
    });
  };

  renderSubmitButton = () => {
    const { groupInfo = {} } = this.state;
    const { groupName = "" } = groupInfo;
    return (
      <Button
        className={LargeCenterModalStyle.saveButton}
        color="info"
        name="Save"
        onClick={() => this.props.onSubmit(groupInfo)}
        status={groupName ? "active" : "inactive"}
      >
        {this.props.isInEditMode ? "Save" : "Create"}
      </Button>
    );
  };

  handleOptionClick = (selected_date_options) => {
    const { groupInfo = {} } = this.state;
    this.setState(() => ({
      groupInfo: set(
        groupInfo,
        `time_setting.selected_date_options`,
        selected_date_options
      ),
    }));
  };

  render() {
    const { groupInfo = {} } = this.state;

    return (
      <div>
        {this.state.showAddItemModal && (
          <Modals.AddItemModal
            addedItems={groupInfo.items}
            destinationName={groupInfo.groupName}
            onAddItem={this.onAddItemToGroup}
            onCloseModal={this.onHideAddItemModal}
          />
        )}
        {this.state.showAddModifierGroupModal && (
          <Modals.AddModifierGroupModal
            addedModifierGroups={groupInfo.modifierGroups}
            destinationName={groupInfo.groupName}
            onAddModifierGroup={this.onAddModifierGroupToGroup}
            onCloseModal={this.onHideAddModifierGroupModal}
          />
        )}
        <Box header="Category Info">{this.renderGroupFields()}</Box>
        <Availability
          groupInfo={groupInfo}
          handleOptionClick={this.handleOptionClick}
          updateTimeSetting={this.updateTimeSetting}
          handleTimeChange={this.handleTimeChange}
        />
        <MenusManagementModules.AddedItemsModule
          items={groupInfo.items}
          onClickAddButton={this.onShowAddItemModal}
          onRemoveItem={this.onRemoveItem}
        />
        <MenusManagementModules.AddedModifierGroupsModule
          modifierGroups={groupInfo.modifierGroups}
          onClickAddButton={this.onShowAddModifierGroupModal}
          onRemoveModifierGroup={this.onRemoveModifierGroup}
        />
        {!this.props.isReadOnly && this.renderSubmitButton()}
      </div>
    );
  }
}

GroupFormFields.propTypes = {
  groupInfo: PropTypes.object,
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

GroupFormFields.defaultProps = {
  groupInfo: {},
  isInEditMode: false,
  isReadOnly: false,
};

export default GroupFormFields;
