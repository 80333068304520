// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import ArchiveButton from "views/MenusManagement/ArchiveButton";
import GroupFormFields from "./GroupFormFields";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions, Services } from "lib";

class MenuGroupModal extends Component {
  confirmNotif = null;

  state = { showLoadingModal: false };

  componentWillUnmount = () => this.setState({ showLoadingModal: false });

  onArchiveGroup = ({ shouldArchiveGroup = false }) => {
    const { Merchants } = Services;
    const { ArchiveMenuGroup } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { context, groupID, groupInfo = {} } = this.props;
    const { shopID } = context;
    ArchiveMenuGroup({ groupID, shouldArchiveGroup, shopID })
      .then(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: `${shouldArchiveGroup ? "Archived" : "Restored"} ${
            groupInfo.groupName
          }`,
          type: "success",
        });
        this.props.onCloseModal();
        this.props.onUpdateAfterGroupArchived({ shouldArchiveGroup });
      })
      .catch(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: "An error has occured. Please try again.",
          type: "error",
        });
      });
  };

  onCreateGroup = async (groupInfo) => {
    const { Merchants } = Services;
    const { CreateNewMenuGroup } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const params = { groupInfo, shopID: this.props.context.shopID };
    const { groupID } = await CreateNewMenuGroup(params);
    if (groupID) {
      this.props.onUpdateAfterGroupCreated({
        category_id: groupID,
        category_info: groupInfo,
      });
      return true;
    }
    return false;
  };

  onSaveGroup = async (groupInfo) => {
    const { Merchants } = Services;
    const { SaveChangedGroupInfo } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { groupID } = this.props;
    const params = { groupID, groupInfo, shopID: this.props.context.shopID };
    if (await SaveChangedGroupInfo(params)) {
      this.props.onUpdateAfterGroupSaved({ groupInfo });
      return true;
    }
    return false;
  };

  onSubmitGroupInfo = async (groupInfo) => {
    const { isInEditMode } = this.props;
    const res = await (isInEditMode
      ? this.onSaveGroup(groupInfo)
      : this.onCreateGroup(groupInfo));
    if (res) {
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: `${isInEditMode ? "Saved" : "Created"} ${groupInfo.groupName}`,
        type: "success",
      });
      this.props.onCloseModal();
    } else {
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error",
      });
    }
  };

  onHideConfirmArchiveModal = () =>
    this.setState({ showConfirmArchiveModal: false });

  onShowConfirmArchiveModal = () =>
    this.setState({ showConfirmArchiveModal: true });

  renderArchiveGroupButton = () => {
    const { groupIsArchived } = this.props.groupInfo;
    return (
      <ArchiveButton
        isInEditMode={this.props.isInEditMode}
        isObjectArchived={groupIsArchived}
        isReadOnly={this.props.isReadOnly}
        name="Archive Category"
        onClick={() => {
          groupIsArchived
            ? this.onArchiveGroup({ shouldArchiveGroup: false })
            : this.onShowConfirmArchiveModal();
        }}
      />
    );
  };

  renderModalTitle = () => {
    if (this.props.isReadOnly) return "Category info";
    return `${this.props.isInEditMode ? "Edit" : "New"} category`;
  };

  render() {
    const { groupInfo, isInEditMode, isReadOnly, context } = this.props;
    const { shopBasicInfo = {} } = context;
    const { openHours = {}, timeZone = "" } = shopBasicInfo;

    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentLabel="Create or edit item modal"
        headerProps={{
          close_button_icon: "arrow",
          header: this.renderModalTitle(),
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.state.showConfirmArchiveModal && (
          <Modals.ConfirmArchiveGroupModal
            groupName={groupInfo.groupName}
            onArchiveGroup={() =>
              this.onArchiveGroup({ shouldArchiveGroup: true })
            }
            onCloseModal={this.onHideConfirmArchiveModal}
          />
        )}
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        <GroupFormFields
          groupInfo={groupInfo}
          isInEditMode={isInEditMode}
          isReadOnly={isReadOnly}
          openHours={openHours}
          onSubmit={this.onSubmitGroupInfo}
          timeZone={timeZone}
        />
        {this.renderArchiveGroupButton()}
      </ResponsiveModal>
    );
  }
}

MenuGroupModal.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  groupID: PropTypes.string,
  groupInfo: PropTypes.object,
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onUpdateAfterGroupArchived: PropTypes.func,
  onUpdateAfterGroupCreated: PropTypes.func,
  onUpdateAfterGroupSaved: PropTypes.func,
};

MenuGroupModal.defaultProps = {
  groupInfo: {},
  isInEditMode: false,
  isReadOnly: false,
};

export default withContext(MerchantInterfaceConsumer)(MenuGroupModal);
