// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class Dropdown extends React.Component {
  state = { show_options: false };

  _getPlaceholder = () => {
    const { placeholder, selected_option_id } = this.props;
    return selected_option_id
      ? this._getSelectedOptionName(selected_option_id)
      : placeholder;
  };

  _getSelectedOptionName = (selected_option_id) => {
    const { options = [] } = this.props;
    if (!selected_option_id) return "";
    else if (Array.isArray(options)) {
      const { name } =
        options.find((option = {}) => {
          const { id = "" } = option;
          return id === selected_option_id;
        }) || {};
      return name;
    }
    return "";
  };

  onHideOptions = () => this.setState({ show_options: false });

  onShowOptions = () => this.setState({ show_options: true });

  onSelectOption = (option) => {
    if (this.props.should_close_modal_after_select_option) this.onHideOptions();
    this.props.onSelectOption(option);
  };

  renderPlaceholder = () => {
    const { custom_placeholder, class_names = {} } = this.props;
    if (custom_placeholder) return custom_placeholder;
    return (
      <div
        className={cx(
          Style.placeholder_container,
          class_names.placeholder_container
        )}
      >
        <div className={cx(Style.placeholder, class_names.placeholder)}>
          {this._getPlaceholder()}
        </div>
        <div className={Style.arrow_icon}>
          <ChevronArrowIcon />
        </div>
      </div>
    );
  };

  renderOptionsArray = () => {
    const { options = [], renderOption } = this.props;
    if (!Array.isArray(options)) return;
    return options.map((option = {}) => {
      const { id, name } = option;
      return (
        <div
          className={Style.option_container}
          onClick={() => this.onSelectOption(option)}
          key={id}
        >
          {renderOption ? (
            renderOption(option)
          ) : (
            <div className={Style.option_name}>{name}</div>
          )}
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <div onClick={this.onShowOptions}>{this.renderPlaceholder()}</div>
        {this.state.show_options && (
          <ResponsiveModal
            {...this.props.modal_props}
            contentLabel="Dropdown options"
            onCloseModal={this.onHideOptions}
          >
            {this.renderOptionsArray()}
          </ResponsiveModal>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  class_names: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  custom_placeholder: PropTypes.any,
  modal_props: PropTypes.object,
  onSelectOption: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  should_close_modal_after_select_option: PropTypes.bool,
  selected_option_id: PropTypes.string,
};

Dropdown.defaultProps = {
  class_names: {},
  options: [],
  onSelectOption: () => {},
  placeholder: "Dropdown",
  should_close_modal_after_select_option: true,
};

export default Dropdown;
