import React from "react";
import PropTypes from "prop-types";
import LargeCenterModalStyle from "components/Modals/style.module.scss";

// Style
import TimePicker from "react-time-picker";
import { ArrowIcon, PencilIcon } from "assets/Icons";
import Style from "./style.module.scss";

import { Box, Button } from "fields";

// Fields
import { RadioGroup } from "components";

// Lib
import { Constants } from "lib";
import RegularHoursEditModal from "./RegularHoursEditModal";

class Availability extends React.Component {
  state = {
    show_regular_hours_edit_modal: false,
    is_mobile_screen: window.innerWidth < 700,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ is_mobile_screen: window.innerWidth < 700 });
  };
  handleStartDateChange = (start_time = "") => {
    this.props.handleTimeChange("start_time", start_time);
  };
  handleEndDateChange = (end_time = "") => {
    this.props.handleTimeChange("end_time", end_time);
  };
  onChangeSelectHours = (selected_date_options = {}) => {
    this.props.handleOptionClick(selected_date_options);
  };
  onClickRadioButton = (fieldID = "", optionID = "") => {
    this.props.updateTimeSetting(fieldID, optionID);
  };
  onClickRadioButtonDate = (fieldID = "", optionID = "") => {
    this.props.updateTimeSetting(fieldID, optionID, true);
  };

  renderAvailability = () => {
    const { NEW_GROUP_AVAILABILITY } = Constants;
    return NEW_GROUP_AVAILABILITY.map((field) => {
      const { fieldKind = "", id = "" } = field;
      return (
        fieldKind === "radio" && (
          <div className={LargeCenterModalStyle.radioForm} key={id}>
            <RadioGroup
              heading={field.label || ""}
              onClick={(optionID) => this.onClickRadioButtonDate(id, optionID)}
              options={field.options || {}}
              required={field.required || false}
              option_props={{
                has_border: true,
                has_border_style: LargeCenterModalStyle.has_border_style,
              }}
              selectedOptionId={
                this.props.groupInfo?.time_setting?.[id] || "every_day"
              }
            />
          </div>
        )
      );
    });
  };

  renderTime = ({ endAt = "", startAt = "" }) => {
    return (
      <div className={Style.time}>
        <TimePicker
          className={Style.time_picker}
          disabled
          disableClock
          value={startAt}
          name="Start time"
        />
        <div style={{ margin: "0 6px" }}>-</div>
        <TimePicker
          className={Style.time_picker}
          disabled
          disableClock
          value={endAt}
          name="End time"
        />
      </div>
    );
  };

  renderSelectionDate = ({ date_selection = "" }) => {
    return date_selection === "custom_date" ? (
      <div
        onClick={() => this.setState({ show_regular_hours_edit_modal: true })}
        style={{ cursor: "pointer" }}
      >
        <div style={{ marginTop: "-20px" }}>{this.renderContent()}</div>
        <Button
          className={Style.edit_special_hours_button}
          name="edit open hours"
        >
          <PencilIcon /> Edit date
        </Button>
      </div>
    ) : null;
  };

  renderHoursEditModal = () => {
    const { groupInfo = {} } = this.props;
    const { time_setting = {} } = groupInfo;
    const {
      selected_date_options = {},
      end_time = "",
      start_time = "",
    } = time_setting;

    const normalized_date_options = Array.isArray(selected_date_options)
      ? selected_date_options.reduce((acc, day) => {
          acc[day] = {
            endAt: end_time || "",
            startAt: start_time || "",
          };
          return acc;
        }, {})
      : selected_date_options;

    return (
      <RegularHoursEditModal
        onChangeSelectHours={this.onChangeSelectHours}
        onCloseModal={() =>
          this.setState({ show_regular_hours_edit_modal: false })
        }
        selected_date_options={normalized_date_options}
      />
    );
  };

  renderExtraHours = ({ day, extraHours = {} }) =>
    Object.keys(extraHours).map((id) => {
      const { endAt = "", startAt = "" } = extraHours[id];
      return (
        <div key={`${day}-${id}`} style={{ marginTop: "6px" }}>
          {this.renderTime({ endAt, startAt })}
        </div>
      );
    });

  renderContent = () => {
    const { DAYS_IN_A_WEEK } = Constants;
    const { groupInfo = {} } = this.props;
    const { time_setting = {} } = groupInfo;
    const {
      selected_date_options = [],
      end_time = "",
      start_time = "",
    } = time_setting;
    const { is_mobile_screen } = this.state;

    return Object.keys(DAYS_IN_A_WEEK).map((day) => {
      let current_day_options = {};

      if (Array.isArray(selected_date_options)) {
        const isDayIncluded = selected_date_options.includes(day);
        if (isDayIncluded) {
          current_day_options = {
            endAt: end_time || "",
            startAt: start_time || "",
            is_all_day: false,
            is_closed_all_day: false,
          };
        }
      } else {
        current_day_options = selected_date_options[day] || {};
      }

      const {
        endAt = "",
        startAt = "",
        is_all_day = false,
        is_closed_all_day = false,
        extraHours = {},
      } = current_day_options;
      const display_day = is_mobile_screen ? day.slice(0, 3) : day;
      return (
        <div className={Style.day_container} key={day}>
          <div className={Style.weekday_label}>{display_day}</div>
          {is_closed_all_day ? (
            <div className={Style.closed_label}>Not Available</div>
          ) : is_all_day ? (
            <div className={Style.all_day_label}>All Day</div>
          ) : (
            <div>
              {this.renderTime({ endAt, startAt })}
              {Object.keys(extraHours).length > 0 &&
                this.renderExtraHours({ day, extraHours })}
            </div>
          )}
        </div>
      );
    });
  };

  renderTimeSelection = ({ date_selection, time_selection }) => {
    return (
      date_selection === "every_day" && (
        <RadioGroup
          heading="Time Selection"
          onClick={(option_id) =>
            this.onClickRadioButton("time_selection", option_id)
          }
          options={{
            all_day: { label: `All Day` },
            custom_time: { label: `Custom Time` },
          }}
          option_props={{
            has_border: true,
            has_border_style: LargeCenterModalStyle.has_border_style,
          }}
          required
          selectedOptionId={time_selection || "all_day"}
        />
      )
    );
  };

  renderCustomTimePicker = ({
    date_selection = "",
    time_selection = {},
    end_time = "",
    start_time = "",
  }) => {
    return (
      date_selection === "every_day" &&
      time_selection === "custom_time" && (
        <div className={LargeCenterModalStyle.heading}>
          <div className={LargeCenterModalStyle.headingText}>Select Time</div>
          <div className={LargeCenterModalStyle.time_pickers_group}>
            <TimePicker
              className={LargeCenterModalStyle.time_picker}
              clearIcon={null}
              disableClock
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={this.handleStartDateChange}
              value={start_time}
            />
            <div className={LargeCenterModalStyle.arrow_icon_time}>
              <ArrowIcon />
            </div>
            <TimePicker
              className={LargeCenterModalStyle.time_picker}
              clearIcon={null}
              disableClock
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={this.handleEndDateChange}
              value={end_time}
            />
          </div>
        </div>
      )
    );
  };

  renderAvailabilityBox = () => {
    const { groupInfo = {} } = this.props;
    const { time_setting = {} } = groupInfo;
    const {
      date_selection = "every_day",
      end_time = "",
      start_time = "",
      time_selection = "all_day",
    } = time_setting;

    return (
      <Box header="Availability">
        {this.renderAvailability()}
        <div>
          {this.renderSelectionDate({ date_selection })}
          {this.renderTimeSelection({ date_selection, time_selection })}
          {this.renderCustomTimePicker({
            date_selection,
            time_selection,
            end_time,
            start_time,
          })}
        </div>
      </Box>
    );
  };

  render() {
    const { show_regular_hours_edit_modal = false } = this.state;

    return (
      <React.Fragment>
        {this.renderAvailabilityBox()}
        {show_regular_hours_edit_modal && this.renderHoursEditModal()}
      </React.Fragment>
    );
  }
}

Availability.propTypes = {
  groupInfo: PropTypes.shape({
    time_setting: PropTypes.object,
  }).isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  updateTimeSetting: PropTypes.func.isRequired,
};
Availability.defaultProps = {
  groupInfo: {},
};
export default Availability;
