import { Constants } from "lib";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const ConvertUTCToLocalTime = ({ utc_time, timeZone }) => {
  const { TIMEZONE_OBSERVE_DST } = Constants;
  if (!utc_time || !timeZone) {
    return "(ConvertUTCToLocalTime) Parameters are not sufficient!";
  }
  let localTime = dayjs.utc(utc_time, "HH:mm");

  if (TIMEZONE_OBSERVE_DST[timeZone]) {
    const { utc_offset_minutes } = TIMEZONE_OBSERVE_DST[timeZone];
    localTime = localTime
      .subtract(utc_offset_minutes, "minute")
      .format("HH:mm");
  } else {
    localTime = localTime.tz(timeZone, false).format("HH:mm");
  }

  return String(localTime) === "undefined" ? "" : localTime;
};

export { ConvertUTCToLocalTime };
